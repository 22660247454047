import React, { useState } from 'react'
import InputField from './InputField'
import SelectField from './SelectField'
import TextareaField from './TextareaField'
import FileInputField from './FileInputField'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { countries } from '../utils/countries'
import { trainings } from '../utils/trainings'

const genders = [
  { label: '* Gender', value: '' },
  { label: 'Female', value: 'Female' },
  { label: 'Male', value: 'Male' },
  { label: 'Other', value: 'Other' },
]

const Add = (props) => {
  const initialState = {
    firstName: '',
    lastName: '',
    country: '',
    company: '',
    profession: '',
    program: '',
    programTime: '',
    testimony: '',
    rating: '',
    gender: '',
    linkedIn: '',
    otherCourses: '',
    gitHub: '',
    fileUrl: '',
    file: '',
    mentor: false,
  }
  const touched = {
    firstName: false,
    lastName: false,
    country: false,
    company: false,
    profession: false,
    program: false,
    testimony: false,
    linkedIn: false,
    rating: false,
    gender: false,
    file: false,
  }

  const [formData, setFormData] = useState(initialState)
  const [errors, setErrors] = useState(touched)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const onChange = (e) => {
    const { name, value, type } = e.target
    setFormData({ ...formData, [name]: value })
    if (type === 'file') {
      const files = e.target.files
      setFormData({
        ...formData,
        file: files[0],
        fileUrl: URL.createObjectURL(e.target.files[0]),
      })
    }
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: e.target.checked })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData()
    for (const key in formData) {
      if (key === 'fileUrl') continue
      data.append(key, formData[key])
    }
    setLoading(true)
    axios
      .post('/api/testimonials', data)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
        }
        navigate('/')
      })
      .catch((err) => {
        setErrors(err.response.data)
        setLoading(false)
      })
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='show-book-list-btn'>
          {/* <Link to='/' className='btn'>
            Show Testimonies
          </Link> */}
          <Link to='/' title='Go testimonials'>
          {/* <i className='fas fa-chevron-left'></i> 
          <i className="fas fa-solid fa-grip-vertical"></i> */}
          <i className='fas fa-solid fa-grip-horizontal'></i>
        </Link>
        </div>
      </div>
      {''}
      <div>
        <h1 style={{ textAlign: 'center' }}>Add testimonial</h1>

        <form noValidate onSubmit={onSubmit}>
          <InputField
            placeholder='First Name'
            name='firstName'
            className='form-control'
            value={formData.firstName}
            onChange={onChange}
            error={errors.firstName}
          />
          <InputField
            id='lastName'
            name='lastName'
            value={formData.lastName}
            onChange={onChange}
            placeholder='Last Name'
            error={errors.lastName}
          />

          <SelectField
            id='gender'
            name='gender'
            value={formData.gender}
            onChange={onChange}
            options={genders}
            error={errors.gender}
          />

          <SelectField
            id='country'
            name='country'
            value={formData.country}
            onChange={onChange}
            options={countries}
            error={errors.country}
          />
          <InputField
            placeholder='Profession'
            name='profession'
            className='form-control'
            value={formData.profession}
            onChange={onChange}
            error={errors.profession}
          />
          <InputField
            placeholder='Company'
            name='company'
            className='form-control'
            value={formData.company}
            onChange={onChange}
          />
          <SelectField
            id='program'
            name='program'
            value={formData.program}
            onChange={onChange}
            options={trainings}
            error={errors.program}
          />
          <TextareaField
            label='Other courses or programs you took from Asabeneh'
            id='otherCourses'
            name='otherCourses'
            value={formData.otherCourses}
            onChange={onChange}
            placeholder='Programs or course separated by comma(e.g HTML, CSS, JS, React, Python)'
            cols='80'
            error={errors.otherCourses}
          />

          <InputField
            label='When did you start the program?'
            type='date'
            name='programTime'
            className='form-control'
            value={formData.programTime}
            onChange={onChange}
          />

          <TextareaField
            label='Testimonial'
            id='testimony'
            name='testimony'
            value={formData.testimony}
            onChange={onChange}
            placeholder='What did you expect and how did the program help you in your career...'
            cols='80'
            error={errors.testimony}
          />
          <InputField
            type='number'
            name='rating'
            className='form-control'
            value={formData.rating}
            onChange={onChange}
            placeholder='Rate the program or course 1 - 5'
            error={errors.rating}
          />

          <InputField
            id='linkedIn'
            name='linkedIn'
            value={formData.linkedIn}
            onChange={onChange}
            placeholder='LinkedIn account link (e.g https://www.linkedin.com/in/asabeneh/)'
            error={errors.linkedIn}
          />

          <InputField
            id='gitHub'
            name='gitHub'
            value={formData.gitHub}
            onChange={onChange}
            placeholder='GitHub account link (e.g https://github.com/Asabeneh)'
          />
          <InputField
            label='Have you been mentoring?'
            type='checkbox'
            name='mentor'
            className='form-control'
            value={formData.mentor}
            onChange={onChange}
          />

          <FileInputField
            name='file'
            id='file'
            onChange={onChange}
            formData={formData}
            text='Upload Image'
            error={errors.file}
          />
          {formData.file.name && (
            <div>
              <img
                src={formData.fileUrl}
                alt={formData.file.name}
                className='image-preview'
              />
            </div>
          )}
          <div>
            <input
              type='submit'
              className='btn'
              value={loading ? 'Sending...' : 'Submit'}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default Add
