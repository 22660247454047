import React from 'react'
import About from './About'
import Items from './Items'

const Home = (props) => (
  <div>
    <About />
    <Items />
  </div>
)

export default Home
